import React from "react";
import { Card } from "react-bootstrap";

const PropheciesDescription = () => {
  return (
    <Card
      style={{
        width: "77.5%",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Card.Img
        variant="top"
        src={require("./propheciesCover.jpg")}
        alt="Prophecies Book"
      />
      <Card.Body>
        <Card.Title style={{ fontSize: "30px" }}>
          Perfectly Fulfilled Prophecies and the Destiny of Mankind
        </Card.Title>
        <Card.Text>
          <html style={{ fontSize: "20px", lineHeight: "1.3" }}>
            <p>
              This book presents very exciting and exclusive results of Dr.
              Zhao's four-year research. In this book, he has reconstructed the
              Biblical chronology (with an uncertainty of less than 6 months)
              and unlocked over 20 numerical Biblical prophecies. Here are some
              examples of the perfectly fulfilled prophecies and the
              end-time prophecies:
            </p>
            <ul style={{ textAlign: "left" }}>
              <li>
                The date of the rebirth of Israel was foretold by Daniel and
                John the Apostle (Ch. 21)
              </li>
              <li>
                Both the start and completion dates of the Dome of the Rock were
                prophesized by Daniel (ch. 19 and Ch. 21)
              </li>
              <li>
                Israel's population at her rebirth exactly matches the number
                prophesized by Ezekiel within 0.21% (Ch. 22)
              </li>
              <li>
                The 30 silver coins Judas received for his betrayal contain
                exactly 17 shekels of silver, as prophesized by Jeremiah
              </li>
              <li>
                Pending numerical prophecies on the end times: Christ may return
                within 7 years! (Ch. 35, Ch. 36 and Ch. 37)
              </li>
            </ul>
          </html>
        </Card.Text>
        <Card.Link
          style={{ fontSize: "25px" }}
          href="https://www.amazon.com/Perfectly-Fulfilled-Prophecies-Destiny-Mankind/dp/1734132612/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Check out on Amazon
        </Card.Link>
      </Card.Body>
    </Card>
  );
};

export default PropheciesDescription;
