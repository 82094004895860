import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import "./style.scss";
import "./style.css";
import logo from "../img/frontier7Logo.png";

const NavigationBar = () => {
  const fontColor = "#6b7a8f";
  return (
    <Navbar collapseOnSelect expand="sm" variant="light" bg="light">
      <Navbar.Brand>
        <Link className="navbar-brand" to="#">
          <img className="logo" src={logo} alt="Logo" />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto"></Nav>{" "}
        <Nav>
          <Nav.Link className="navBarLink" style={{ color: "red !important" }}>
            <Link to="/home" style={{ color: fontColor }}>
              Home
            </Link>
          </Nav.Link>
          <Nav.Link className="navBarLink">
            <Link to="/contact" style={{ color: fontColor }}>
              Contact
            </Link>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;
