import React from "react";

const Contact = () => {
  return (
    <h1
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      Coming soon
    </h1>
  );
};

export default Contact;
