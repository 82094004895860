import React from "react";

const Header = () => {
  return (
    <div className="hero-image">
      <img src={require("./heroImage.jpg")} alt="Hero" width="100%"></img>
    </div>
  );
};

export default Header;
