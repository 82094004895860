import React from "react";
import Header from "./Header/Header";
import Featured from "./Featured/Featured";
import Footer from "./Footer/Footer";

const Home = () => {
  return (
    <div style={{ height: "100%" }}>
      <Header />
      <Featured />
      <Footer />
    </div>
  );
};

export default Home;
