import React from "react";

import PropheciesDescription from "./PropheciesDescription";
import PaulDescription from "./PaulDescription";

const Featured = () => {
  return (
    <div className="container-fluid body">
      <div style={{ marginTop: "5%", textAlign: "center", color: "#6b7a8f" }}>
        <div style={{ fontSize: "60px" }}>OUR FEATURED BOOKS</div>
      </div>
      <div style={{ marginTop: "5%" }} className="row">
        <div className="col column">
          <PropheciesDescription />
        </div>
        <div className="col column">
          <PaulDescription />
        </div>
      </div>
    </div>
  );
};

export default Featured;
