import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import logo from "./frontier7Logo.png";

const Footer = () => {
  return (
    <MDBFooter className="font-small pt-4 mt-4">
      <MDBContainer
        fluid
        style={{ backgroundColor: "#f8f9fa" }}
        className="text-center text-md-left"
      >
        <MDBRow>
          <MDBCol style={{ marginTop: "25px" }} md="6">
            <img className="logo" src={logo} alt="Logo" />
          </MDBCol>
          <MDBCol style={{}} md="6">
            <h5 style={{ marginTop: "25px" }} className="title">
              Links
            </h5>
            <ul>
              <li className="list-unstyled">
                Check out &nbsp;
                <i>Perfectly Fulfilled Prophecies and the Destiny of Mankind</i>
                &nbsp; on &nbsp;
                <a
                  href="https://www.amazon.com/Perfectly-Fulfilled-Prophecies-Destiny-Mankind/dp/1734132612/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Amazon
                </a>
              </li>
              <li className="list-unstyled">
                Check out &nbsp;
                <i>Jesus vs Paul on Christianity</i>
                &nbsp; on &nbsp;
                <a
                  href="https://www.amazon.com/Jesus-Paul-Christianity-Peter-Zhao/dp/1734132647/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Amazon
                </a>
              </li>
            </ul>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div
        style={{ backgroundColor: "#6b7a8f" }}
        className="footer-copyright text-center py-3"
      >
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Copyright:{" "}
          <a
            href="https://www.frontier7publishing.com"
            style={{ color: "black" }}
          >
            {" "}
            frontier7publishing.com{" "}
          </a>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
};

export default Footer;
