import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import NavigationBar from "./Components/NavigationBar/NavigationBar";
import Home from "./Components/Home/Home";
import Contact from "./Components/Contact/Contact";
import NotFound from "./Components/NotFound/NotFound";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <NavigationBar />

        <main>
          <Switch>
            <Route path="/home" component={Home}></Route>
            <Route path="/contact" component={Contact}></Route>
            <Route path="/not-found" component={NotFound}></Route>
            <Redirect from="/" exact to="/home" />
            <Redirect to="/not-found" />
          </Switch>
        </main>
      </React.Fragment>
    );
  }
}

export default App;
