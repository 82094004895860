import React from "react";
import { Card } from "react-bootstrap";

const PaulDescription = () => {
  return (
    <Card
      style={{
        width: "65%",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Card.Img
        variant="top"
        src={require("./paulCover.jpg")}
        alt="Jesus vs Paul Book"
      />
      <Card.Body>
        <Card.Title style={{ fontSize: "30px" }}>
          Jesus vs Paul on Christianity
        </Card.Title>
        <Card.Text>
          <html style={{ fontSize: "20px", lineHeight: "1.3" }}>
            <ul style={{ textAlign: "left" }}>
              <li>The history and formation of the New Testament canon</li>
              <li>
                Unambiguous proof for the canonicity of the three New Testament
                books: Matthew, John, and Revelation
              </li>
              <li>
                Unambiguous proof for the authorship of Revelation and the time
                it was written
              </li>
              <li>
                Irrefutable contradictions between Luke's books and the three
                canonized New Testament books
              </li>
              <li>
                Detailed comparisons of the teachings of Jesus and his disciples
                with those of Paul
              </li>
              <li>The narrow road towards the kingdom of God</li>
            </ul>
          </html>
        </Card.Text>
        <Card.Link
          style={{ fontSize: "25px" }}
          href="https://www.amazon.com/Jesus-Paul-Christianity-Peter-Zhao/dp/1734132647/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Check out on Amazon
        </Card.Link>
      </Card.Body>
    </Card>
  );
};

export default PaulDescription;
